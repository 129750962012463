<template>
  <div>
    <the-menu :title="title" :color="color" />
    <div class="container">
        <div v-if="isHost">
            <Telephant :hostProp="true" :test="true" :agentProp="UserAgent" v-if="UserAgent && userClient" :userProp="userClient"/>
        </div>
        <div v-else>
            <Telephant :test="true"/>
        </div>
    </div>
  </div>
</template>

<script>
import Telephant from './../../../components/user/janus/Telephant';
import TheMenu from './../../../components/TheMenu'

export default {
    components:{
        Telephant,
        TheMenu
    },
    data() {
        return {
            isHost: false,
            UserAgent: '',
            userClient: '',
            title: '',
            color: ''
        }
    },
    beforeMount(){
        this.isHost = this.$route.query.ishost ? true : false; 
        this.title = this.$route.query.title;
        this.color = this.$route.query.color;
        let random = this.getRandomInt(1,999); 
        this.UserAgent = `UserAgent-${random}`;
        this.userClient = `UserClient-${random}`;
    },
    methods: {
        getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min)) + min;
        }
    }
}
</script>

<style></style>