<template>
  <div>

    <ModalApprovedVeriff v-if="this.status === 200" :openModal="openModalApproved" :uuid="uuid"
      :jtaValidations="jtaValidations" textClose="Enviar" title="Decision Veriff"
      v-on:closeModal="() => (openModalApproved = false)"></ModalApprovedVeriff>

    <the-menu />


    <div v-if="this.status === 204" class="mb-4">
      <article class="message is-warning">
        <div class="message-header">
          <p>Información</p>
        </div>
        <div class="message-body">
          <p>El Usuario no tiene procesos con veriff</p><br>
          <button class="button is-danger" @click="resetForm">Nueva consulta</button>
        </div>
      </article>
    </div>

    <div v-else class="box">
      <div class="columns">
        <div class="column is-10">
          <h4 class="title is-5">{{ this.uuid.verification.status }}</h4>
          <div class="card" style="width: 70%;">
            <div class="card-content">
              <div class="content">
                <h3 class="title is-3">Client Information</h3>
                <div class="plataform">
                  <span class="subtitle is-7" style="margin-left: 10px;"> First name </span>
                  <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.clientFirstName }} </span>
                </div>

                <div class="plataform">
                  <span class="subtitle is-7" style="margin-left: 10px;"> Last name </span>
                  <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.clientLastName }} </span>
                </div>

                <div class="plataform">
                  <span class="subtitle is-7" style="margin-left: 10px;"> Personal number </span>
                  <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.clientDocumentNumber }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="buttons">
            <button class="button is-primary" @click="openModalApproved = true">Approved / Decline</button>
            <button class="button is-danger" @click="resetForm">Nueva consulta</button>
          </div>
        </div>
      </div>

      <div class="content" v-if="this.status === 200"><br>
        <h6 class="subtitle is-6">RISK-LABELS</h6>
        <div v-for="risk in uuid.verification.riskLabels" :key="risk.id">
          <span class="icon-text">
            <span>
              <font-awesome-icon color="red" icon="times-circle"></font-awesome-icon>
              {{ risk.category }}
            </span>
          </span>
          <span class="notify">
            {{ risk.label }}
          </span>
        </div>
      </div>

      <div>
        <div class="plataform">
          <span class="subtitle is-7" style="margin: 10px;"> IP location </span>
          <span class="subtitle is-7" style="margin: 10px;"> {{ this.uuid.technicalData.ip }} </span>
        </div>
      </div><br>

      <div class="columns" v-if="this.status === 200">
        <div class="column">
          <h6 class="subtitle is-6">Personal</h6>
          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> First name </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.person.firstName }} </span>
          </div>

          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Last name </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.person.lastName }} </span>
          </div>

          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Personal number </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.person.idNumber }} </span>
          </div>

          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Date of birth </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.person.dateOfBirth }}
            </span>
          </div>
        </div>
        <div class="column">
          <h6 class="subtitle is-6">Document</h6>
          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Document contry </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.document.country }} </span>
          </div>

          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Document type </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.document.type }} </span>
          </div>

          <div class="plataform">
            <span class="subtitle is-7" style="margin-left: 10px;"> Document number </span>
            <span class="subtitle is-7" style="margin-left: 10px;"> {{ this.uuid.verification.document.number }} </span>
          </div>
        </div>
      </div><br>

      <div v-if="disabledButton" class="notification is-warning">
        Algo ocurrio al cargar las images, porfavor reintentalo
      </div>

      <div v-if="disabledButton" class="content">
        <button class="button is-primary" @click="getAlternativeImages()">Cargar imagenes</button>
      </div>

      <progress v-if="loading" class="progress is-small is-primary" max="100">15%</progress>

      <div v-if="disabledCards" class="columns">
        <div class="column is-4">
          <div class="card" style="height: 470px">
            <div class="card-content">
              <div class="content">
                <h6 class="subtitle is-6">Portrait</h6>
                <div style="align-items: center;">
                  <agile>
                    <div v-for="img in portraitArray" :key="img.id" class="slide">
                      <figure>
                        <img :src='img.image'>
                      </figure>
                    </div>
                  </agile>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="card" style="height: 470px">
            <div class="card-content">
              <div class="content">
                <h6 class="subtitle is-6">Document front</h6>
                <div style="align-items: center;">
                  <agile>
                    <div v-for="img in documentFrontArray" :key="img.id" class="slide">
                      <figure>
                        <img :src='img.image'>
                      </figure>
                    </div>
                  </agile>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="disabledCards" class="columns">
        <div class="column is-4">
          <div class="card" style="height: 470px">
            <div class="card-content">
              <div class="content">
                <h6 class="subtitle is-6">Document back</h6>
                <div style="align-content: center;">
                  <agile>
                    <div v-for="img in documentBackArray" :key="img.id" class="slide">
                      <figure>
                        <img :src='img.image'>
                      </figure>
                    </div>
                  </agile>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="card" style="height: 470px">
            <div class="card-content">
              <div class="content">
                <h6 class="subtitle is-6">Video</h6>
                <div style="align-items: center;">
                  <video width="200px" height="150px" controls :src="this.video">
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import TheMenu from "@/components/TheMenu"
import TheUserSearch from "@/components/user/TheUserSearch"
import LoanDesist from "@/components/LoanDesist"
import FormBasicInformation from "@/components/user/FormBasicInformation"
import FormUbica from "@/components/user/FormUbica"
import FormSignature from "@/components/user/FormSignature"
import FormOTP from "@/components/user/FormOTP"
import FormPersonalInformation from "@/components/user/FormPersonalInformation"
import FormResidentialInformation from "@/components/user/FormResidentialInformation"
import FormLaboralInformation from "@/components/user/FormLaboralInformation"
import FormElectronicId from "@/components/user/FormElectronicId"
import FormBankInfo from "@/components/user/FormBankInfo"
import FormDisbursementMethod from "@/components/user/FormDisbursementMethod"
import Simulator from "@/components/simulator"
import UbicaInformation from "@/components/UbicaInformation"
import PreDisbursement from "@/components/user/FormPredisbursement"
import Scraping from "@/components/user/FormScraping"
//UI
import ModalAlert from "@/components/UI/ModalAlert"
import Modal from "@/components/UI/Modal"
import ModalApprovedVeriff from "@/components/UI/ModalApprovedVeriff"
//Slider
import { VueAgile } from 'vue-agile'

export default {
  data() {
    return {
      errorMessage: "",
      modalDesist: false,
      modalUbica: false,
      uuid: {},
      jtaValidations: [],
      portrait: "",
      portraitArray: {},
      documentFrontArray: {},
      documentBackArray: {},
      documentFront: "",
      documentBack: "",
      video: "",
      openModalApproved: false,
      openModalDecline: false,
      option: "Select a option",
      rejectPerson: false,
      rejectDocument: false,
      rejectDevice: false,
      rejectSession: false,
      rejectMismacth: false,
      disabledButton: false,
      disabledCards: false,
      loading: false,
      interval: null
    }
  },
  props: {
    codeUuid: {
      type: String,
      default: ' '
    },
    uuidData: {
      type: Object
    },
    sessionId: {
      type: String
    },
    loanId: {
      type: Number
    },
    status: {
      type: Number
    }
  },
  async mounted() {
    if (this.status === 204) {
      this.uuid = {}
    } else {
      this.uuid = await this.uuidData
      if (!this.uuid.images.length) {
        this.disabledButton = true
        this.disabledCards = false
      } else {
        this.disabledButton = false
        this.disabledCards = true
        this.portraitArray = this.uuid.images.filter(image => image.imageType.includes('face'))
        this.documentFrontArray = this.uuid.images.filter(image => image.imageType.includes('document-front'))
        this.documentBackArray = this.uuid.images.filter(image => image.imageType.includes('document-back'))
        this.video = this.uuid.videos[0].video
      }
      this.cleanData()
      await this.getDepartments()
      await this.getSelectorForm()
      await this.getWorkingtypeForm()
      let selectors = await this.getJtaValidations
      this.jtaValidations = selectors
    }
  },
  components: {
    TheMenu,
    TheUserSearch,
    LoanDesist,
    FormBasicInformation,
    FormUbica,
    FormSignature,
    FormOTP,
    FormPersonalInformation,
    FormResidentialInformation,
    FormLaboralInformation,
    FormElectronicId,
    FormBankInfo,
    Simulator,
    ModalAlert,
    Modal,
    UbicaInformation,
    FormDisbursementMethod,
    PreDisbursement,
    Scraping,
    ModalApprovedVeriff,
    agile: VueAgile
  },
  methods: {
    openModalVeriff() {
      this.openModalApproved = true
    },
    async getAlternativeImages() {
      this.loading = true
      this.disabledButton = false
      const loanId = this.loanId
      const sessionId = this.sessionId
      const resp = await this.getImages({ loanId, sessionId })
      if (resp === 200) {
        this.interval = setInterval(this.getData, 10000);
      } else {
        this.$notyf.error(resp, "Hubo un error")
      }
    },
    getData() {
      const loanId = this.loanId
      const uuid = this.codeUuid
      this.getUuid(loanId, uuid)
    },
    async getUuid(loanId, uuid) {
      const uuidInfo = await this.getUuidInfo({ loanId, uuid })
      this.uuid = uuidInfo
      if (!uuidInfo.data.images.length) {
        //console.log('aun no hay imagenes')  
      } else {
        this.loading = false
        this.disabledButton = false
        this.disabledCards = true
        clearInterval(this.interval)
        this.portraitArray = uuidInfo.data.images.filter(image => image.imageType.includes('face'))
        this.documentFrontArray = uuidInfo.data.images.filter(image => image.imageType.includes('document-front'))
        this.documentBackArray = uuidInfo.data.images.filter(image => image.imageType.includes('document-back'))
        this.video = uuidInfo.data.videos[0].video
      }
    },
    async desist() {
      try {
        let loanActive = this.getloanActive
        await this.postLoanDesist(loanActive.id)
        this.modalDesist = false
        this.cleanData()
      } catch (err) {
        this.$notyf.error(err.message)
        this.modalDesist = false
      }
    },
    openSignature() {
      this.completeFormStep("bankInformation")
      this.$scrollTo(`#lbl_approvedAwaitingSignature`)
    },
    resetForm() {
      this.cleanData()
      this.$router.push({
        name: "table",
      })
    },
    async DoneScrapping() {
      try {
        let loan = this.getLoadScrapping
        if (loan === undefined) {
          throw new Error("Error en el estado de credito.")
        }
        await this.postLoanDoneScrapping(loan.id)
        this.setError({
          errorStatus: false,
          errorMessage: ""
        })
        this.initialForm()
        await this.getClient({
          value: this.getDocumentNumber,
          method: "id"
        })
      } catch (err) {
        this.$notyf.error(err.message)
      }
    },
    async showForm(step_clicked) {
      if (this.enabledClick && this.isStepCompleted(step_clicked)) {
        let basicInfo = this.getBasicInfo

        await this.ubicaUser({
          documentNumber: basicInfo.cc,
          surname: basicInfo.surname
        })
        if (step_clicked == "personalInformation") {
          this.enabledFormStep([
            "personalInformation",
            "residentialInformation",
            // "references",
            "laboralInformation"
          ])
        }
        if (step_clicked == "residentialInformation") {
          this.enabledFormStep([
            "residentialInformation",
            // "references",
            "laboralInformation"
          ])
        }
        // if (step_clicked == "references") {
        //   this.enabledFormStep(["references", "laboralInformation"])
        // }
        if (step_clicked == "laboralInformation") {
          this.enabledFormStep(["laboralInformation"])
        }
        if (step_clicked == "simulator") {
          this.enabledFormStep(["simulator"])
        }
        if (step_clicked == "identityValidation") {
          this.enabledFormStep(["identityValidation"])
        }
        this.$scrollTo(`#lbl_${step_clicked}`)
      }
    },
    openModalUbica() {
      this.modalUbica = true
    },
    ...mapActions({
      cleanData: "user/cleanData",
      getSelectorForm: "user/getSelectorForm",
      getWorkingtypeForm: "user/getWorkingtypeForm",
      getDepartments: "user/getDepartments",
      ubicaUser: "user/getUbica",
      postLoanDesist: "user/postLoanDesist",
      postLoanDoneScrapping: "user/postLoanDoneScrapping",
      getClient: "user/getClient",
      getUuidInfo: "user/getUuidInfo",
      getImages: "user/getImages"
    }),
    ...mapMutations({
      enabledFormStep: "user/enabledFormStep",
      completeFormStep: "user/completeFormStep",
      setError: "form/setError",
      initialForm: "user/initialForm"
    })
  },
  computed: {
    btnDesist() {
      let londActive = this.getloanActive
      let error = this.isError
      let id_inactive = [500, 400, 300, 203]
      if (
        londActive &&
        (!error || [101].includes(londActive.status.id)) &&
        !id_inactive.includes(londActive.status.id)
      ) {
        return true
      } else {
        return false
      }
    },
    btnSignature() {
      let londActive = this.getloanActive
      let error = this.isError
      let id_inactive = [201, 200]
      if (
        londActive &&
        !error &&
        id_inactive.includes(londActive.status.id) &&
        this.currentStatus === "bankInformation"
      ) {
        return true
      } else {
        return false
      }
    },
    btnScrappingDone() {
      return this.getLoadScrapping ? true : false
    },
    ...mapGetters({
      isError: "form/isError",
      getError: "form/getError",
      getBasicInfo: "user/getBasicInfo",
      currentStatus: "user/currentStatus",
      isStepCompleted: "user/isStepCompleted",
      enabledClick: "user/enabledClick",
      getloanActive: "user/getloanActive",
      getUbica: "user/getUbica",
      getLoadScrapping: "user/getLoadScrapping",
      getDocumentNumber: "user/getDocumentNumber",
      getJtaValidations: "user/getJtaValidations",
      getBasicUuidInfo: "user/getBasicUuidInfo"
    })
  }
}
</script>

<style>
.agile {
  width: 100%;
}

.agile__actions {
  margin-top: 20px;
}

.agile__nav-button {
  background: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 24px;
  transition-duration: .3s;
}

.agile__nav-button:hover {
  color: #888;
}

.agile__dot {
  margin: 0 20px;
}

.agile__dot button {
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: .3s;
  width: 10px;
}

.agile__dot--current button,
.agile__dot:hover button {
  background-color: #fff;
}

.slide {
  align-items: center;
  color: #fff;
  display: flex;
  height: 300px;
  justify-content: center;
}

.slide h3 {
  font-size: 32px;
  font-weight: 300;
}

.notify {
  background-color: #feecf0;
  color: #CC0F35;
  margin-left: 2%;
  max-width: 300px;
  border-radius: 2%;
  size: 10px;
}

.message-header {
  border-radius: 0px;
}

.wrapper {
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

label {
  display: block;
  padding: 10px;
  border: 1px solid #fff;
  border-bottom: none;
  font-weight: bold;
  background: rgb(216, 216, 216);
}

label.allowed_clic {
  cursor: pointer;
}

.selectable {
  cursor: pointer !important;
}

label:hover,
label.completed {
  background: $primary;
  color: $white;
}

label.last {
  border-bottom: 1px solid #fff;
}

ul li .content {
  padding: 15px;
}

input[type="checkbox"] {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]~.content {
  height: 0;
  transform: scaleY(0);
}

input[type="checkbox"]:checked~.content {
  height: 100%;
  transform-origin: top;
  transition: transform 0.2s ease-out;
  transform: scaleY(1);
}

input[type="checkbox"]:checked+label {
  background: $primary;
  color: $white;
  border-bottom: 1px solid #fff;
}


.VueAgile {
  background-color: #000;
  /*       background: transparent
      border: none
      color: #fff
      cursor: pointer
      font-size: 24px
      height: 100%
      position: absolute
      top: 0
      transition-duration: .3s
      width: 80px */
  /* 
		&:hover{
      background-color: rgba(#000, .5)
			opacity: 1
    }
			

		&--prev{
      left: 0
    }
			

		&--next{
      right: 0
    }
			

	&__dots{
    bottom: 10px
		left: 50%
		position: absolute
		transform: translateX(-50%)
  }


	&__dot{
    margin: 0 10px
  }
		

		.button{
      background-color: transparent
			border: 1px solid #fff
			border-radius: 50%
			cursor: pointer
			display: block
			height: 10px
			font-size: 0
			line-height: 0
			margin: 0
			padding: 0
			transition-duration: .3s
			width: 10px
    } 

		&--current,
		&:hover
			button{
        background-color: #fff
      }
				

    // Slides styles	
    .slide{
      display: block
      height: 500px
      object-fit: cover
      width: 100%
    } */

}
</style>
