<template>
  <div class="modal" :class="{ 'is-active': open }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal"
          title="Cerrar"
        ></button>
      </header>
      <section class="modal-card-body p-4">

        <div class="field">

          <div> 
            <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> First name </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.person.firstName}} </span>
            </div>

            <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Last name </span>
            <span class="subtitle is-6" style="margin: 10px;align-content: left;"> {{this.uuid.verification.person.lastName}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Status </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.status}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Document number </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.document.number}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Cpmpletion date </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.decisionTime}} </span>
            </div>

             <div v-if="uuid.verification.status === 'abandonned'" class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Expiry date </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.decisionTime}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Date of birth </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.person.dateOfBirth}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Failure reason </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.reasonCode}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> IP </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.technicalData.ip}} </span>
            </div>

             <div class="plataform">
            <span class="subtitle is-6" style="margin: 10px;"> Acceptancetime </span>
            <span class="subtitle is-6" style="margin: 10px;"> {{this.uuid.verification.acceptanceTime}} </span>
            </div>

          </div><br>
          <div class="columns">
            <div class="column is-one-third">
              <label class="label">Decision final</label>
              <div class="control">
                <div class="select">
                  <select v-model="option">
                    <option value="0" >Select a option</option>
                    <option value="1309" >Approve</option>
                    <option value="1310" >Reject</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column">
              <div v-show="option === '1310'" class="control">
                <label class="label">Razon del rechazo</label>
                <div class="select">
                  <select v-model="rejectReasson">
                    <option value="0" >Select a option</option>
                    <option v-for="item in jtaValidation" :key="item.id" :value="item.value" > {{item.label}} </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      <footer class="modal-card-foot has-text-right">
        <button class="button is-success" @click="sendDesicion">
          <span v-if="textClose !== ''">{{ this.textClose }}</span>
          <span v-else>Enviar</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, } from "vuex"

export default {
  data() {
    return {
      open: false,
      option: 0,
      rejectReasson: 0,
      jtaValidation: [],
      form: {
        decisionFinal: 0,
        firstName: "",
        lastName: "",
        nationality: "",
        gender: "",
        status: "",
        documentNumber: "",
        decisionTime: "",
        placeOfBirth: "",
        acceptanceTime: "",
        dateOfBirth: "",
        failureReason: 0,
        ip: ""
      }
    }
  },
  async mounted (){
    const jtaVal = await this.getJtaValidations
    this.jtaValidation = jtaVal
  },
  methods: {
    closeModal() {
      this.open = false
      this.$emit("closeModal")
    },
    async sendDesicion() {
      this.formContruct()
      try {
        await this.postLastDesicion(this.form)
        this.$notyf.success("Decision tomada")
      } catch (error) {
        this.$notyf.error(error, 'code: ', error.code)
      }
    },
    formContruct() {
      this.form.decisionFinal = parseInt(this.option)
      this.form.firstName = this.uuid.verification.person.firstName
      this.form.lastName = this.uuid.verification.person.lastName
      this.form.nationality = this.uuid.verification.person.nationality
      this.form.gender = this.uuid.verification.person.gender
      this.form.status = this.uuid.verification.status
      this.form.documentNumber = this.uuid.clientDocumentNumber
      this.form.decisionTime = this.uuid.verification.decisionTime
      this.form.placeOfBirth = this.uuid.verification.person.placeOfBirth
      this.form.acceptanceTime = this.uuid.verification.acceptanceTime
      this.form.dateOfBirth = this.uuid.verification.person.dateOfBirth
      this.form.failureReason = this.rejectReasson
      this.form.ip = this.uuid.technicalData.ip
    },
    ...mapActions({
      postLastDesicion: "user/postLastDesicion",
    }),
  },
  props: {
    openModal: Boolean,
    title: {
      type: String,
      required: true
    },
    textClose: {
      type: String,
      default: ""
    },
    uuid: {
      type: Object
    },
    jtaValidations: {
      type: Array
    }
  },
  watch: {
    openModal: function() {
      if (this.openModal) {
        this.open = true
      }
    }
  },
  computed: {
    ...mapGetters({
      getJtaValidations: "user/getJtaValidations",
    })
  }
}
</script>