<template>
  <div>
    <the-menu />
    <div class="box">
      <div class="card">
        <div class="form">
          <header class="card-header">
            <div class="columns">
              <div class="column is-one-third">
                <date-picker v-model="time1" valueType="format" placeholder="Fecha inicial"></date-picker>
              </div>
              <div class="column is-one-third">
              <date-picker v-model="time2" valueType="format" placeholder="Fecha final"></date-picker>
              </div>
              <div class="column is-one-third">
                <input v-model="document" class="input" type="number" placeholder="Documento de identidad">
              </div>
              <div class="column is-one-third">
                <button class="button is-info" @click="validParams()" >Buscar</button>
              </div>
            </div>
          </header>
        </div>
      </div><br>
      <div class="box" v-if="table.totalRecords">   
        <table class="table is-bordered">
        <thead>          
          <th>UUID</th>
          <th>Creado el</th>
          <th>Loan Id</th>
          <th>Result</th>
          <th>Acciones</th>
        </thead>
        <tfoot>
        <div class="columns">
          <div class="column">
            <pagination v-model="page" :records="table.totalRecords" :per-page="perPage" @paginate="searchPagination(page)"/>
          </div>
          <div class="column is-one-fifth">
            <div class="control">
            <label class="label">Orden</label>
                <div class="select">
                  <select v-model="order" @click="changeOrder()">
                    <option value="asc" >asc</option>
                    <option value="desc">desc</option>
                  </select>
                </div>
              </div>
            </div>
          <div class="column is-one-fifth">
            <div class="control">
            <label class="label">Por pagina</label>
              <div class="select">
                <select v-model="perPage" @click="searchMoreData(perPage)">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        </tfoot>
        <tbody v-for="row in table.clients" :key="row.id">
          <th>{{row.uuid}}</th>
          <th>{{row.createdAt.year}}-{{row.createdAt.monthValue}}-{{row.createdAt.dayOfMonth}}/
              {{row.createdAt.hour}}:{{row.createdAt.minute}}:{{row.createdAt.second}}</th>
          <th>{{row.loanId}}</th>
          <th>{{row.result}}</th>
          <th>
          <button class="button" @click="copyToClipboard(row.uuid)">
            <font-awesome-icon color="primary" icon="copy"></font-awesome-icon>
          </button>
          <button class="button">
            <font-awesome-icon @click="searchUuid(row.uuid, row.loanId, row.sessionId)" color="primary" icon="search"></font-awesome-icon>
          </button>
          </th>
        </tbody><br>
      </table>
      </div>

    </div>
  </div>
</template>

<script>
import TheMenu from "@/components/TheMenu"
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/locale/es"
import { required } from "vuelidate/lib/validators"
import { mapActions, mapGetters } from "vuex"
import Pagination from 'vue-pagination-2';

const date = new Date()
const minDate = new Date(date.getFullYear() - 80, 1, 1)
const maxDate = new Date().setFullYear(date.getFullYear() - 18)

const convertDate = fecha => {
  let year = fecha.slice(6, 10)
  let month = fecha.slice(3, 5)
  let day = fecha.slice(0, 2)
  return new Date(`${year}-${month}-${day}`)
}

export default ({
  data() {
    return {
      errorMessage: "",
      time1: null,
      time2: null,
      document: "",
      payload: {
        documentNumber: "",
        initDate: 0,
        finDate: 0
      },
      table: [],
      page: 1,
      perPage: 10,
      perPages: "10",
      order: "asc",
      loading: false
    }
  },
  components: {
    TheMenu,
    DatePicker,
    Pagination
  },
  validations: {
    expeditionDate: {
      required,
      max: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() > date ? false : true
        } else {
          return true
        }
      },
      min: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() < minDate.getTime() ? false : true
        } else {
          return true
        }
      }
    },
    birthdate: {
      required,
      max: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() > maxDate ? false : true
        } else {
          return true
        }
      },
      min: fecha => {
        if (fecha) {
          return convertDate(fecha).getTime() < minDate.getTime() ? false : true
        } else {
          return true
        }
      }
    },
    gender: { required },
    dependents: { required },
    educationalLevel: { required },
    maritalStatus: { required }
  },
  methods: {  
    async validParams(){
      /* Se validan los datos de las fechas para que se ingresen dechas validas */
      const dateInit  = new Date(this.time1)
      const dateEnd = new Date(this.time2)
      this.payload.documentNumber = this.document
      if (dateInit < dateEnd) {
        /* en caso de estar correctas se hace la busqueda */
        this.search(dateInit, dateEnd)
      } else if (dateInit > dateEnd) {
        /* en caso contrario se le notifica al usuario */
        this.$notyf.error("La fecha inicial no puede ser mayor a la de fin")
      } else {
        this.search(dateInit, dateEnd)
      }
    }, 
    async search(dateInit, dateEnd){
      /* se genera la busqueda de la informacion */
      this.page = 1
      const dateInitlong = dateInit*1
      const dateEndLong = dateEnd*1
      this.payload.initDate = dateInitlong
      this.payload.finDate = dateEndLong

      const data = await this.postPending({
        pageNumber: this.page, 
        pageSize: this.perPage, 
        order: this.order, 
        payload: this.payload
      })
      this.table = data
    },
    async searchPagination(page){
      /* busqueda por medio de la paginacion */
      const dateInit  = new Date(this.time1)
      const dateEnd = new Date(this.time2)
      const dateInitlong = dateInit*1
      const dateEndLong = dateEnd*1
      this.payload.initDate = dateInitlong
      this.payload.finDate = dateEndLong

      const data = await this.postPending({
        pageNumber: page, 
        pageSize: this.perPage, 
        order: this.order, 
        payload: this.payload
      })
      this.table = data
    },
    async searchMoreData(){
      /* en caso de querer mas datos por pagina */
      this.perPage = parseInt(this.perPage)
      const page = 1
      this.searchPagination(page)
    },
    async changeOrder(){
      /* cambio de orden de ascendente a descendente y en caso contrario */
      const page = 1
      this.searchPagination(page)
    },
    copyToClipboard(uuid){
      /* copia del uuid al portapapeles */
      navigator.clipboard.writeText(uuid);
      this.$notyf.success("UUID copiado")
    },
    async searchUuid(uuid, loanId, sessionId){
      /* busqueda de la data por medio del uuid sin cambiar de vista */
        try {
          this.loading = true
          let uuidInfo = await this.getUuidInfo({uuid, loanId})
          if (uuidInfo.status === 204) {
            this.$router.push({
            name: "veriff",
            params:{
              codeUuid: uuid,
              uuidData: {},
              sessionId: sessionId,
              loanId: loanId,
              status: uuidInfo.status
            } })
          } else {
            this.$router.push({
            name: "veriff",
            params:{
              codeUuid: uuid,
              uuidData: uuidInfo.data,
              sessionId: sessionId,
              loanId: loanId,
              status: uuidInfo.status
            } })
          }
          this.loading = false
        } catch (error) {
          this.loading = false
          this.$notyf.error(error.message)
        }
    },
    ...mapActions({
      cleanData: "user/cleanData",
      postPending: "user/postPendingTable",
      getUuidInfo: "user/getUuidInfo"
    }),
  },
  computed: {
    ...mapGetters({
      pendingTable: "user/getPending"
    })
  }
})
</script>

<style>
  .button{
    border: none;
    outline: none;
  }

  .input{
    height: 85%;
  }
</style>
